import React from 'react';

import Layout from '../components/Layout';
import pic4 from '../assets/images/pic04.jpg';
import pic5 from '../assets/images/pic05.jpg';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>About Me</h2>
          <p>Experienced Web Developer From Southampton, UK</p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">How I got started</h3>
          <p>
            My first experience with programming started with good old Windows
            batch files, at the time I played a lot of Source Engine games, and
            with that came Source Recorder, a built in recorder for recording
            your gaming footage, the way that worked is you would set a
            framerate, and it would basically take screenshots as fast as that
            framerate. That in turn would create thousands of png files in the
            game's directory, so I experiemented with batch files to delete them
            all once I was done compiling them into videos (you can watch one of
            these videos{' '}
            <a
              href="https://www.youtube.com/watch?v=ka0uw3nCpTs"
              target="_blank"
            >
              here
            </a>
            ) crazy that video is 13 years old and I still play that game a bit
            now!
          </p>

          <p>
            During my time of playing Source games, I learnt how to do mapping
            (creating maps in the game engine) and from that I started making
            tutorial videos and putting them on Youtube, one of them has around
            40,000 views! Shortly after starting that an online friend
            approached me and said he get me a website setup so I can post
            articles on it about the tutorials I was doing, and that is where I
            heard about PHP...
          </p>

          <h3 className="major">PHP (PHP Hypertext Preprocessor)</h3>
          <p>
            Learning PHP was hard! I had no experience with anything like it
            beforehand and so learning everything from scratch took a long time,
            I started off by making stupid website ideas and using vanilla PHP
            with HTML and CSS and connecting it all to a mysql database. Later
            on I started looking into PHP frameworks and the first one I used
            was{' '}
            <a href="https://codeigniter.com/" target="_blank">
              CodeIgniter
            </a>{' '}
            I found it incredibly as for the first time it made it easy to get
            data from the database without having to write loads of SQL, after
            that I went onto{' '}
            <a href="https://laravel.com" target="_blank">
              Laravel
            </a>{' '}
            and absolutely loved it, it made working with PHP a real joy. I
            learned enough of it to land my first job as a junior PHP developer
            working on massive sites and I was thrown into the deepend.
          </p>
          <h3 className="major">Moving forward</h3>
          <p>
            Over the last few years I have worked on a massive amount of
            projects big and small and I really enjoy doing what I do, but one
            challenge I have found with web development is how the industry is
            constantly changing and so as developer I have to stay on top of it
            or risk missing out on the cool new technologies. Currently I'm
            getting into NodeJs, ReactJs, and VueJs... see a pattern there? yep,
            all javascript based frameworks. Some people say they find working
            with Javascript to be a bit of a challenge, but compared to PHP, I
            have found it super interesting to work with and hope to work with
            it more in the future.
          </p>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
